import router from '../router/index'
import CryptoJs from 'crypto-js'
import BigNumber from 'bignumber.js'
import settings from '../settings'
const Utils = {
  isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  },
  isNotEmptyString(str) {
    return typeof str === 'string' && str !== ''
  },
  isNotString(str) {
    return typeof str !== 'string'
  },
  isNotNaNNumber(num) {
    return typeof num !== 'number' && Number.isNaN(num)
  },
  encode(str) {
    var encode = encodeURI(str)
    return btoa(encode)
  },
  parseQueryString (url) {
    if (!url) {
      return ''
    }
    let queryString = url.slice(url.indexOf('?') + 1)
    let tempArr = queryString.split('&')
    let obj = {}
    tempArr.forEach(item => {
      let arr = item.split('=')
      obj[arr[0]] = arr[1]
    })
    return obj
  },
  randomString(len) {
    len = len || 32
    let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
    let maxPos = $chars.length
    let pwd = ''
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return pwd
  },
  /**
   * num1-num2,避免精度丢失
   * @param {减数} num1
   * @param {被减数} num2
   * @returns
   */
  numSub(num1, num2) {
    var baseNum, baseNum1, baseNum2;
    var precision; // 精度
    try {
      baseNum1 = num1.toString().split(".")[1].length;
    } catch (e) {
      baseNum1 = 0;
    }
    try {
      baseNum2 = num2.toString().split(".")[1].length;
    } catch (e) {
      baseNum2 = 0;
    }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;
    return (Number((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(2)) + '';
  },
  /**
   * 解密方法
   * @param: str 需要解密的字符
   * @param: key 密钥
   * @param: iv 密钥偏移量
   */
  decrypt(str, key, iv) {
    const keyStr = CryptoJs.enc.Latin1.parse(key);
    const ivStr = CryptoJs.enc.Latin1.parse(iv);

    const encryptedStr = CryptoJs.AES.decrypt(str, keyStr, {
      iv: ivStr,
      mode: CryptoJs.mode.CBC,
      padding: CryptoJs.pad.Pkcs7
    });
    return encryptedStr.toString(CryptoJs.enc.Utf8);
  },
  /**
   * 公共跳转方法
   * @param {String} path 需要跳转的pathName
   * @param {*} type 是否是本地环境
   * @returns
   */
  openNewWindow(path) {
    if (!path) {
      return
    }
    if (parent && Object.prototype.toString.call(parent) === '[object Object]' && parent.frames.length > 0) {
      if (parent && typeof parent.postMessage === 'function') {
        parent.postMessage({
          type: 'iframes',
          path: path.path
        }, '*')
      }
    } else {
      if (path._blank) {
        router.push(path)
      } else {
        let routeData = router.resolve(path)
        console.log('打开新页面', routeData.href)
        window.open(routeData.href, '_blank')
      }
    }
  },

  /**
   *
   * @param {String} subData 需要格式化的主体名称
   * @param {Object} formData 当前页的form表单数据
   */
  formatSubList(subData, formData) {
    let arr = []
    if (formData[subData].length !== 0) {
      this.$refs[subData].selected.forEach((item, index) => {
        arr.push({
          id: item.value,
          name: item.label
        })
      })
    }
    return arr
  },

  /**
   *
   * @param {String} subIDStr 需要格式化的主体 id
   * @returns  主体id 数组
   */
  formatSubId(subIDStr) {
    if(subIDStr === 'null') {
      return []
    }
    if (subIDStr) {
      let arr = subIDStr.split(',').filter(item => item)
      return arr
    } else {
      return []
    }
  },

  /**
   *
   * @param {String} subStr 需要格式化的主体 名称 去掉两端 ，
   * @returns 两端没有 ， 字符串
   */
  formatSubName(subStr) {
    if (subStr) {
      let _arr = subStr.split(',').filter(item => item)
      let str = _arr.join(',')
      return str
    } else {
      return ''
    }
  },

  limitRule(value, name, formName) {
    value = value.replace(/[^\d.]/g, "") // 除了- 数字 和 . 替换成空
    value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
    value = value.replace(/^\./, "0.")
    value = value.replace(/^(\-)*(\d+)\.(\d{0,8}).*$/, '$1$2.$3')

    if (name && formName) {
      formName[name] = value
    } else {
      return value
    }
  },

  /**
   *
   * @param {String} keyWord select 远程搜索关键字
   * @param {Array} originList 搜索列表
   * @param {Object} config 配置限制字符数 和 搜索关键字
   * @returns
   */
  optionDataFilter(keyWord, originList, config = { limit: 4, nameKey: 'name' }) {
    // 搜索字符小于4个字不展示搜索结果
    const { limit, nameKey } = config
    if (typeof keyWord === 'string' && keyWord.length < limit) {
      return []
    }
    if (!Array.isArray(originList)) return[]
    let res = originList.filter(element => {
      let str = element[nameKey].toLocaleLowerCase()
      let lowKeyWord = keyWord.toLocaleLowerCase()
      return str.indexOf(lowKeyWord) !== -1
    })
    return res
  },

  loginOut() {
    if (parent && typeof parent.postMessage === 'function' && parent.frames.length > 0) {
      parent.postMessage({
        type: 'loginOut'
      }, '*')
    } else {
      const url = settings.CAPITAL_BASE_URL()
      window.location.href = url
    }
  },
  // 大数计算
  BigNumberCacl(x, y, symbols, fixedCheck) {
    if ((x == 0 || y == 0) && (symbols === '*' || symbols === '/')) {
      return '0'
    }
    if ((!x && x != 0) || (!y && y != 0)) {
      return
    } else {
      x = x.toString() // 数值转字符串
      y = y.toString()
    }
    let a = new BigNumber(x) // 使用bignumber
    let b = '' // 定义返回值
    let d = [] // 定义计算获得的数组
    let e // 计算后次方数
    let s // 是否负数
    if (symbols === '+') {
      d = a.plus(y).c
      e = a.plus(y).e
      s = a.plus(y).s
    } else if (symbols === '-') {
      d = a.minus(y).c
      e = a.minus(y).e
      s = a.minus(y).s
    } else if (symbols === '*') {
      d = a.multipliedBy(y).c
      e = a.multipliedBy(y).e
      s = a.multipliedBy(y).s
    } else {
      d = a.dividedBy(y).c
      e = a.dividedBy(y).e
      s = a.dividedBy(y).s
    }

    if (e > 13) {
      d = d.splice(0, 3)
      if (d.length === 1) { // 大于14次方没有小数数组第二个全为0被抹除
        d[1] = 0
      }
      if (d[1].toString().length < 14) { // 大于14次方数组第二个存在0被抹除
        d[1] = (Array(14).join(0) + d[1]).slice(-14)
      }
    }
    if (e >= 0 && e < 14 && d.length > 2) {
      d = d.slice(0, 2)
    } else if (e < 0 && d.length > 1) {
      d = d.slice(0, 1)
    }
    if ((e <= 13 && d.length > 1) || (e > 13 && d.length > 2)) { // 判断有无小数对小数进行处理并且拼接成字符串
      if (fixedCheck && fixedCheck === 'noFixed') {
        b = d.splice(0, d.length - 1).join('') + (d.pop() / 1e14).toString().slice(1)
      } else {
        if ((d[d.length - 1] / 1e14).toFixed(2) >= 1) {
          d[d.length - 2] += 1
        }
        b = d.splice(0, d.length - 1).join('') + (d.pop() / 1e14).toFixed(2).toString().slice(1)
      }
    } else {
      if (e >= 0) {
        b = d.splice(0, d.length).join('')
        b = b + '.00'
      }
    }
    if (e < 0) { // 只有小数时在前方补0
      if (fixedCheck && fixedCheck === 'noFixed') {
        b = (d[0] / 1e14).toString()
      } else {
        b = (d[0] / 1e14).toFixed(2).toString()
      }
    }
    if (s && s == -1) {
      return '-' + b
    } else {
      return b
    }
  }
}

export default Utils
