import { CAPITAL_BASE_URL } from '../settings'
class CheckIframes {
  constructor() {
    this.host = window.location.host
  }

  init() {
    // 本地环境默认不拦截
    if (this.host.includes('localhost')) {
      return
    }
    // 其他环境如果是在iframes中嵌入，不拦截
    if (parent && typeof parent.postMessage === 'function' && parent.frames.length > 0) {
      return
    }
    window.location.href = CAPITAL_BASE_URL()
  }
}

export default new CheckIframes()
