import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import maiaUI from '@longfor/maia-ui'
import ElementUI from 'element-ui'
import maVueUtils from "@longfor/ma-vue-utils"
import utils from './utils/index'
import request from './api/index'
import settings from './settings'
import checkIframes from './utils/checkIframes'
import '@/svgIcon/index.js'

checkIframes.init()
import * as filters from './utils/filters.js'

import 'element-ui/lib/theme-chalk/index.css'

import HaloOssUpload from '@longfor/halo-upload'
Vue.component('haloOssUpload', HaloOssUpload)

import ImageViewer from '@/components/ImageViewer/Index'
import supplier from '@/directive/supplier-tag'
Vue.component('ImageViewer', ImageViewer)

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(maiaUI)
Vue.use(maVueUtils)
Vue.directive(supplier)

Vue.prototype.$open = utils.openNewWindow
Vue.prototype.$formatSubList = utils.formatSubList
Vue.prototype.$limitRule = utils.limitRule
Vue.prototype.$optionDataFilter = utils.optionDataFilter
Vue.prototype.$request = request
Vue.prototype.$settings = settings
import '@/components/AuthControl' // 权限组件
// 引入请求地址列表
import url from '@/plugin/url.config.js'
Vue.prototype.URL = url

Object.keys(filters).forEach(k => Vue.filter(k, filters[k]))

window.authority = utils.randomString(32)
// 注册一个全局自定义指令 `v-auth`
// Vue.directive('auth', {
//   componentUpdated: (el, binding, vnode) => {
//     const value = binding.value
//     // const auth = store.state.authority
//     // const castgc = sessionStorage.getItem('castgc')
//     const auth = sessionStorage.getItem(`authority|${window.authority}`) || ''
//     console.log(auth)
//     if (!auth.includes(value)) {
//       //el.parentNode.removeChild(el)
//       el.style.display = "none"
//     }
//   },
//   bind: (el, binding, vnode) => {
//     const value = binding.value
//     // const auth = store.state.authority
//     // const castgc = sessionStorage.getItem('castgc')
//     const auth = sessionStorage.getItem(`authority|${window.authority}`) || ''
//     console.log(auth)
//     if (!auth.includes(value)) {
//       //el.parentNode.removeChild(el)
//       el.style.display = "none"
//     }
//   }
// })

// 注册全局指令 `v-defaultSelect` 设置主体增加不可删除
Vue.directive('defaultSelect', {
  componentUpdated(el, bindings, vnode) {
    // values v-model 绑定值
    // options 下拉选项
    // console.log('el>>>',el)
    const [values, options] = bindings.value

    if(!values) {
      return
    }

    // 需要隐藏的标签索引
    const indexs = []
    values.forEach((item, index) => {
      options.forEach(it => {
        if (item === it.id && it.disabled === true) {
          indexs.push(index)
        }
      })
    })

    // console.log("indexs>>>>",indexs)
    const dealStyle = function (tags) {
      tags.forEach((el, index) => {
        if (indexs.includes(index) && ![...el.classList].includes('select-tag-close-none')) {
          el.classList.add('hidden-delete')
          // el.remove()
        }
      })
    }
    // 设置样式 隐藏close icon
    const tags = el.querySelectorAll('.el-tag__close')
    if (tags.length === 0) {
      // 初始化tags为空处理
      setTimeout(() => {
        const tagTemp = el.querySelectorAll('.el-tag__close')
        dealStyle(tagTemp)

      }, 200)
    } else {
      dealStyle(tags)
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
