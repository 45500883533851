import Vue from 'vue'
import settings from '@/settings'
import store from '@/store/index.js'
const isUAT = settings.isUAT
const isProduction = settings.isProduction
Vue.component('AuthControl', {
  render: function () {
    // console.log(this.visible ? this.$slots.default : this.$slots.tip)
    /* istanbul ignore next */
    return this.visible ? this.$slots.default : this.$slots.tip
  },
  props: {
    code: String
  },
  created() {
    /* istanbul ignore next */
    // this.visible = this.AuthControl(this.code)
  },
  computed: {
    authority() {
      return store.state.authority
    },
    visible() {
      return this.AuthControl(this.code)
    }
  },
  methods: {
    AuthControl(code) {
      return code ? !!this.authority.includes(code) : true
    }
  }
})

function AuthControl(code) {
  // console.log(code)
  /* istanbul ignore next */
  console.log(!!this.authority.includes(code), this.authority)
  return code ? !!this.authority.includes(code) : true
  // if (isUAT || isProduction) {
  //   /* istanbul ignore next */
  //   return code ? !!store.state.authority[code] : true
  // }
  // const hostname = window.location.hostname
  // if (hostname === 'localhost') {
  //   return code ? !!store.state.authority[code] : true
  // }
  // return true
}

export default AuthControl
