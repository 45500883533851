<template>
  <div class="image__preview">
    <el-image-viewer
      ref="imageDom"
      :on-close="closeViewer"
      :maskClosable="maskClosable"
      :url-list="urls">
    </el-image-viewer>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  data() {
    return {
      maskClosable: true
    }
  },
  components: { ElImageViewer },
  props: {
    urls: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    closeViewer() {
      this.$emit('closeViewer', false)
    }
  }
}
</script>

<style scoped>
</style>