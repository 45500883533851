import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'
import baseUrl from '../api/baseUrl.js'
import Utils from '@/utils'
const service = axios.create({
  // 设置超时时间
  timeout: 60000,
  baseURL: baseUrl + "/cms/inner",
  withCredentials: true,
  headers: {}
})
// 设置post的请求头为application/x-www-form-urlencoded;charset=UTF-8
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'


/**
 * 请求响应拦截
 * 用于处理需要在请求返回后的操作
 */
service.interceptors.response.use(response => {
  const res = response.data
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  if (res.code !== "200") {
    if (res.code == "303" || res.code == "500") {
      if (process.env.NODE_ENV !== 'development') {
        Utils.loginOut()
      }
      return
    }
    Message.error(res.message || "请求错误")
  }
  return Promise.resolve(res)
},
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          Message.error("错误请求")
          break;
        case 500:
          Message.error("服务端异常，请稍后重试")
          break;
      }
    }
    return Promise.resolve(error)
  })
Vue.prototype.service = service
export default service

