let baseUrl = ''
// switch (process.env.VUE_APP_MODE) {
//     case 'development':
//         baseUrl = ""
//         break;
//     case 'product':
//         baseUrl = ""
//         break;
//     case 'uat':
//         baseUrl = ""
//         break;
// }

export default baseUrl;
