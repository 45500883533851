<template>
  <div class="content content_box">
    <!-- 顶部固定 -->
    <div
      class="fixed_position content"
      ref="fixBox"
    >
      <!-- 标题 -->
      <div class="list-title">
        <h4 class="title">合同台账</h4>
        <h4 class="title" style="color:#FFFFFF;">dummy change</h4>
        <div class="btn-box">
          <AuthControl code="FC_SHCMS_HTTZ_CGWJ">
            <button
              class="btn btn-lucency"
              :class="draftDisable ? 'draftDisabledClass' : ''"
              :disabled="draftDisable"
              @click="goDraftPage"
            >
              草稿文件
            </button>
          </AuthControl>
          <AuthControl code="FC_SHCMS_HTTZ_CGWJ">
            <span
              class="scaling"
              :class="digits? 'scalings' : 'scaling'"
              v-if="number"
            >{{ number }}</span>
          </AuthControl>
          <AuthControl code="FC_SHCMS_HTTZ_XJ">
            <button
              class="btn btn-primary"
              @click="goCreatContract"
            >
              <i class="el-icon-circle-plus-outline icon_style"></i>
              新建合同
            </button>
          </AuthControl>
        </div>
      </div>

      <!-- 搜索栏 -->
      <div class="searchContent">
        <div class="searchBar">
          <div>
            <el-input
              placeholder="请输入合同名称、经办人等关键词进行搜索"
              class="input-style"
              size="small"
              v-model="searchList.keyWord"
              clearable
              @keyup.enter.native="searchEventHandle"
            >
              <i
                slot="prefix"
                class="el-input__icon el-icon-search"
              ></i>
            </el-input>
          </div>
          <!-- 按钮组件 -->
          <div>
            <button
              class="btn btn-primary"
              @click="searchListByOptions()"
            >查询</button>
            <button
              class="btn btn-lucency"
              @click="resetSearchList()"
            >
              重置
            </button>
            <!-- 是否含有筛选列表 -->
            <button
              class="btn"
              @click="showSearchList = !showSearchList"
            >
              <span
                class="search-option"
                v-if="!showSearchList"
              >
                展开筛选
                <i class="el-icon-caret-bottom caretIcon"></i>
              </span>
              <span
                class="search-option"
                v-if="showSearchList"
              >
                收起筛选
                <i class="el-icon-caret-top caretIcon"></i>
              </span>
            </button>
          </div>
        </div>
        <!-- 搜索组件 -->
        <div
          v-show="showSearchList"
          class="searchList"
        >
          <el-form
            ref="form"
            :model="searchList"
            label-width="80px"
            size="small"
          >
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="合同编号:"
                  prop="contractNo"
                >
                  <el-input
                    placeholder="请输入编号"
                    class="input-style"
                    size="small"
                    v-model.trim="searchList.contractNo"
                    @input="verify"
                    @keydown.enter.native="searchListByOptions"
                    clearable
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="我方签约主体:"
                  prop="our_company"
                  class="label_style"
                >
                  <el-select
                    placeholder="请输入不少于4个字的主体名称进行查询"
                    v-model="searchList.ourCompany"
                    style="width: 100%"
                    collapse-tags
                    multiple
                    filterable
                    remote
                    :remote-method="selectFilterOurCompanyFn"
                  >
                    <template v-for="(item, index) of ourCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="对方签约主体:"
                  prop="supplierCompany"
                  class="label_style"
                >
                  <el-select
                    v-model="searchList.supplierCompany"
                    style="width: 100%"
                    collapse-tags
                    multiple
                    filterable
                     remote
                    :remote-method="selectFilterOurCompanyFn1"
                    placeholder="请输入不少于4个字的主体名称进行查询"

                  >
                    <template v-for="(item, index) of supplierCompanyList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="合同状态:"
                  prop="contractStatus"
                >
                  <el-select
                    v-model="searchList.contractStatus"
                    style="width: 100%"
                    :clearable="true"
                  >
                    <template v-for="(item, index) of contractStatusList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  label="付款状态:"
                  prop="payStatus"
                >
                  <el-select
                    v-model="searchList.payStatus"
                    style="width: 100%"
                    :clearable="true"
                  >
                    <template v-for="(item, index) of payStatusList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="经办人:"
                  prop="operatorNo"
                >
                  <el-select
                    v-model="searchList.operatorNo"
                    style="width: 100%"
                    :clearable="true"
                    filterable
                  >
                    <template v-for="(item, index) of operatorList">
                      <el-option
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="经办日期:"
                  prop="contractDate"
                >
                  <el-date-picker
                    v-model="searchList.contractDate"
                    type="daterange"
                    :unlink-panels="true"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="合同类型:" prop="contractType">
                  <el-select v-model="searchList.contractType" style="width: 100%" :clearable="true">
                    <template v-for="(item, index) in contractTypeList">
                      <el-option
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      />
                    </template>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <!-- </section> -->
        </div>
      </div>
    </div>
    <!-- 列表展示部分 -->
    <div
      class="tableContent"
      ref="tableBox"
    >
      <el-table
        v-loading="tableLoading"
        ref="tableList"
        :cell-style="{height:36+'px'}"
        :data="tableData"
        style="width: 100%;"
        border
        class="tableClass"
      >
        <div
          slot="empty"
          class="emptyBox"
        >
          <div>
            <img
              src="../assets/img/empty.png"
              alt=""
            />
            <p>暂无合同数据</p>
          </div>
        </div>
        <!-- 合同名称 -->
        <el-table-column
          label="合同名称"
          prop="contractName"
          min-width="200"
          fixed="left"
        >
          <template slot-scope="scope">
            <div
              class="rescindBox"
              v-if="scope.row.rescindFlag == 'Y'"
            ></div>
            <p
              class="rescind"
              v-if="scope.row.rescindFlag == 'Y'"
            >已解除</p>
            <div
              class="contractNameBox"
              :class="scope.row.rescindFlag === 'Y'?'contractNameBox-rescind':'contractNameBox'"
            >
              <span
                class="nameLink"
                @click="goConDetail(scope.row)"
              >
                {{ scope.row.contractName }}
              </span>
              <span
                v-if="
                  scope.row.contractAgreements &&
                  scope.row.contractAgreements.length
                "
                class="SupAgreementBox"
                :class="scope.row.rescindFlag === 'Y'?'SupAgreementBox-rescind':'SupAgreementBox'"
                @mouseover="showSupAgreement($event, scope.$index, scope.row)"
                @mouseout="closeSupAgreement($event)"
              >
                合同变更({{ scope.row.contractAgreements.length }})
              </span>
            </div>
          </template>
        </el-table-column>
        <!-- 关联项目 -->
        <el-table-column
          label="关联项目"
          prop="projectName"
          min-width="95"
        >
          <template slot-scope="scope">
            {{ scope.row.projectName | empty }}
          </template>
        </el-table-column>
        <!-- 币种 -->
        <el-table-column
          label="币种"
          prop="currency"
          min-width="50"
        >
          <template slot-scope="scope">
            {{ scope.row.currency | empty }}
          </template>
        </el-table-column>
        <!-- 合同金额 -->
        <el-table-column
          prop="contractAmount"
          align="right"
          min-width="100"
          label="合同金额"
        >
          <!-- <template slot="header">
            <p class="over-title">合同金额</p>
            <p class="last-title">A</p>
          </template> -->
          <template slot-scope="scope">
            {{ scope.row.contractAmount | millimeterFormat | empty }}
          </template>
        </el-table-column>
        <!-- 已付款金额 -->
        <el-table-column
          prop="paySumAmount"
          align="right"
          min-width="100"
          label="已付款金额"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus !== 'nopayment'">{{scope.row.paySumAmount | millimeterFormat | empty }}</span>
            <span v-else>{{ '-' }}</span>
          </template>
        </el-table-column>
        <!-- 中间信息 -->
        <el-table-column
          v-for="(item, index) in tableColumn"
          :show-overflow-tooltip="true"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :align="item.align"
          :fixed="item.fixed"
          :min-width="item.minWidth"
        ></el-table-column>
        <el-table-column
          label="合同类型"
          prop="contractType"
        >
        </el-table-column>
        <!-- 合同状态 -->
        <el-table-column
          label="合同状态"
          fixed="right"
          min-width="75"
          prop="contractStatus"
        >
          <template slot-scope="scope">
            <div class="statusBox">
              <span
                class="statusIcon"
                :class="handelConStatus(scope.row.contractStatus)"
              ></span>
              <span class="statusName">{{
                scope.row.contractStatus | conStatusNameFilter
              }}</span>
            </div>
          </template>
        </el-table-column>
        <!-- 付款状态 -->
        <el-table-column
          label="付款状态"
          fixed="right"
          min-width="90"
          prop="payStatus"
        >
          <template slot-scope="scope">
            <div class="statusBox">
              <span
                class="statusIcon"
                :class="handelPayStatus(scope.row.payStatus)"
              ></span>
              <span class="statusName">{{
                scope.row.payStatus | payStatusNameFilter
              }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 补充协议列表 -->
      <ul
        id="agreementListBox"
        ref="agreementListBox"
        v-show="isMouseenter"
        @mouseleave="closeSupAgreement($event)"
      >
        <li
          v-for="(item, index) in agreementList"
          :key="index"
        >
          <span @click="goAgreeDetail(item)">{{ index + 1 + "." + item.agreementName }}
          </span>
        </li>
      </ul>

      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConParameter",
  // components: {
  //   TitleBar
  // },

  data() {
    return {
      T01List: [], // 我方签约主体
      T02List: [], // 对方签约主体
      currUserId: "", // 当前登录人ID
      clickState: false,
      draftDisable: true,
      //搜索项
      searchList: {
        keyWord: ""
      },
      showSearchList: false, // 是否展开搜索列表
      // 搜索-我方签约主体
      ourCompanyList: [],
      // 搜索-对方签约主体
      supplierCompanyList: [],
      // 搜索-合同状态
      contractStatusList: [
        { label: "审批中", value: "approval" },
        { label: "已审批", value: "approved" },
        { label: "被驳回", value: "rejected" },
        { label: "已撤回", value: "withdrawn" },
        { label: "协商中", value: "negotiating" },
        { label: "已盖章", value: "stamped" },
        { label: "已归档", value: "archived" }
      ],
      // 搜索-合同类型
      contractTypeList: [
        { label: '主合同', value: '主合同' },
        { label: '补充协议', value: 'SIDE_AGREEMENT' },
        { label: '主体变更', value: 'SUBJECT_CHANGE' },
        { label: '解除协议', value: 'RELEASE_AGREEMENT' }
      ],
      // 搜索-付款状态
      payStatusList: [
        { label: "未付款", value: "unpaid" },
        // { label: "付款中", value: "paying" },
        { label: "部分已付", value: "partially" },
        { label: "付款完成", value: "completed" },
        { label: "无需付款", value: "nopayment" }
      ],
      // 搜索-经办人
      operatorList: [],
      isMouseenter: false, // 补充协议框显示
      number: "", // 草稿数量
      digits: false, // 草稿数量是否两位数
      draftId: "", // 草稿ID
      draftType: "", // 草稿类型
      show: false, // 查询列表是否展开
      // 表头列表
      tableColumn: [
        {
          label: "经办人",
          prop: "operatorName",
          minWidth: 80
        },
        {
          label: "经办日期",
          prop: "contractDate",
          minWidth: 90
        },
        {
          label: "合同编号",
          prop: "contractNo",
          minWidth: 140
        },
        {
          label: "对方签约主体",
          prop: "supplierCompany",
          minWidth: 130
        }
      ],
      // 表数据
      tableData: [],
      // 补充协议显示列表
      agreementList: [],
      // 分页
      currentPage: 1,
      total: 0,
      pageSize: 30,

      // 表格loading
      tableLoading: false
    };
  },
  methods: {
    selectFilterOurCompanyFn(val) {
      this.ourCompanyList = this.$optionDataFilter(val,this.T01List)
    },
    selectFilterOurCompanyFn1(val) {
      this.supplierCompanyList = this.$optionDataFilter(val,this.T02List)
    },
    searchEventHandle() {
      this.currentPage = 1
      this.getContractList()
    },
    // 重置按钮
    resetSearchList() {
      // 清空搜索项
      this.searchList = {
        keyWord: ""
      }
      this.currentPage = 1
    },

    // 验证合同编号搜索框
    verify() {
      this.searchList.contractNo = this.searchList.contractNo.replace(
        /[^\a-\z\A-\Z0-9\-_]/g,
        ""
      );
    },

    // 合同补充协议显示
    showSupAgreement(event, index, rowInfo) {
      this.agreementList = Array.from(rowInfo.contractAgreements);
      this.isMouseenter = true;

      // 处理显示位置
      let showDiv = this.$refs["agreementListBox"]

      showDiv.style.left = event.pageX - event.offsetX - 40 + "px"
      showDiv.style.display = "block"

      this.$nextTick(() =>{
        let liList = showDiv.children
        let height = 0
        liList.forEach(item =>{
          height = height + item.offsetHeight
        })

        if (height > 140) {
          showDiv.style.height = 140 + "px"
          showDiv.style.top = event.pageY - event.offsetY - 150 + "px"
          return
        }
        showDiv.style.height = height + "px"
        showDiv.style.top = event.pageY - event.offsetY - 12 - height + "px"

      })

    },
    // 隐藏补充协议链接框
    closeSupAgreement(event) {
      if (event.offsetY >-5 && event.offsetY < 0 && event.target.id !== "agreementListBox") {
        this.isMouseenter = true;
        return;
      }
      this.isMouseenter = false;
    },

    //跳转到合同详情页或补充协议
    async goConDetail(row) {
      this.tableLoading = true
      const url = `/contract/getContractStatus?contractType=${row.dataType}&id=${row.id}`
      const res = await this.$request.get(url)
      this.tableLoading = false
      let status = res.data;
      let type = row.dataType;
      let bizFlag = row.bizFlag
      if (type == "contract_agreement") {
        // 跳转至补充协议
        if (this.currUserId !== row.operatorNo) {
          this.$open({
            name: "agreeDetail",
            path: `/agreedetail/${row.id}`,
            params: {
              id: row.id
            }
          });
          return
        }
        if(bizFlag === "1" && (status === "withdrawn" || status === "rejected")) {
          this.$message({
            type: 'warning',
            message: '该合同变更为业务类合同变更，请到业务系统编辑',
            duration: 1500
          })
          return
        }
        if (bizFlag === "0" && (status === "withdrawn" || status === "rejected")) {
          this.$open({
            name: "agreementEdit",
            path: `/agreementedit/${row.id}`,
            params: {
              id: row.id
            }
          });
          return
        }
        this.$open({
          name: "agreeDetail",
          path: `/agreedetail/${row.id}`,
          params: {
            id: row.id
          }
        });
      } else {
        // 跳转至合同详情
        if (this.currUserId !== row.operatorNo) {
          this.$open({
            name: "conDetail",
            path: `/condetail/${row.id}`,
            params: {
              id: row.id
            }
          });
          return
        }
        if(bizFlag === "1" && (status === "withdrawn" || status === "rejected")) {
          this.$message({
            type: 'warning',
            message: '该合同为业务类合同，请到业务系统编辑',
            duration: 1500
          })
          return
        }
        if (bizFlag === "0" && (status === "withdrawn" || status === "rejected")) {
          this.$open({
            name: "conEdit",
            path: `/conedit/${row.id}`,
            params: {
              id: row.id
            }
          });
          return;
        }

        this.$open({
          name: "conDetail",
          path: `/condetail/${row.id}`,
          params: {
            id: row.id
          }
        });
      }
    },

    // hover框 跳转补充协议详情页
    goAgreeDetail(agreement) {
      let status = agreement.auditStatus;
      if (this.currUserId !== agreement.operatorNo) {
        this.$open({
          name: "agreeDetail",
          path: `/agreedetail/${agreement.id}`,
          params: {
            id: agreement.id
          }
        });
        return;
      }
      if (status === "withdrawn" || status === "rejected") {
        this.$open({
          name: "agreementEdit",
          path: `/agreementedit/${agreement.id}`,
          params: {
            id: agreement.id
          }
        });
        return;
      }
      this.$open({
        name: "agreeDetail",
        path: `/agreedetail/${agreement.id}`,
        params: {
          id: agreement.id
        }
      });
    },

    // 跳转到草稿页
    goDraftPage() {
      if (this.number == 1) {
        //判断是什么合同类型 跳转草稿详情页
        if (this.draftType == "contract_h") {
          this.$open({
            name: "conDraft",
            path: `/condraft/${this.draftId}`,
            params: {
              id: this.draftId
            }
          });
          return;
        }
        this.$open({
          name: "agreementDraft",
          path: `/agreementdraft/${this.draftId}`,
          params: {
            id: this.draftId
          }
        });
      } else {
        // 否则跳转草稿列表页
        this.$open({
          name: "draft",
          path: "/draft"
        });
      }
    },

    // 跳转到新建合同页
    goCreatContract() {
      this.$open({
        name: "creatCon",
        path: "/creatcon"
      });
    },

    //获取草稿页数量
    getDraftCount() {
      this.$request.get("/contract/getDraftCount").then(res => {
        if (res.code === "200") {
          let data = res.data;
          let num = data.count;
          if (num == 0) {
            this.draftDisable = true
            return
          }
          this.draftDisable = false
          num > 99 ? (this.number = "99+") : (this.number = num)
          num > 9 ? (this.digits = true) : (this.digits = false)
          if (num == 1) {
            this.draftId = data.data.id;
            this.draftType = data.data.dataType;
          }
        }
      });
    },

    // 点击查询按钮进行查询
    searchListByOptions() {
      this.currentPage = 1
      this.getContractList()
    },

    // 获取筛选列表里的主体列表
    getSearchList() {
      this.$request.post("/enterprise/queryEnterpriseAll").then(res => {
        this.T01List = res.data.T01
        this.T02List = res.data.T02
        // this.ourCompanyList = res.data.T01
        // this.supplierCompanyList = res.data.T02
      });
    },

    // 获取筛选列表里的经办人列表
    getSearchOperaterList() {
      this.$request.post("/employee/queryEmployeeAll?scope=all").then(res => {
        this.operatorList = res.data
      });
    },

    // 获取合同台账列表
    getContractList() {
      this.tableLoading = true
      let params = this.searchList
      if(params.contractDate) {
        params.startTime = params.contractDate[0]
        params.endTime = params.contractDate[1]
      }else {
        params.startTime = '',
        params.endTime = ''
      }
      params.keyWord = params.keyWord.trim()
      params.range = "contract"
      params.pageSize = this.pageSize
      params.pageNum = this.currentPage

      this.$request
        .post("/contract/getContractOrDraftList", { params })
        .then(res => {
          this.tableLoading = false
          if (res.code == "200") {
            let data = res.data.list
            this.tableData = data.list
            this.total = Number(data.total)
            this.currUserId = res.data.currUserId
            this.clickState = false

            // 处理对方签约主体展示
            this.tableData.forEach(item => {
              if(item.supplierCompany) {
                item.supplierCompany = item.supplierCompany.slice(1, item.supplierCompany.length - 1)
              }else {
                item.supplierCompany = "-"
              }
            })
          }
        });
    },

    /**
     * 分页相关的方法
     */
    handleSizeChange(val){
      this.pageSize = val
      this.getContractList()
    },
    handleCurrentChange(value){
      this.currentPage = value
      this.getContractList()
    },

    /**
     * 初始化加载币种，发票类型，和形成方式
     */
    initCurrency(){
      this.$request.post('/dic/queryDicByType?type=CURRENCY').then(res => {
        if(res.code === "200") {
          localStorage.setItem("currencyList",JSON.stringify(res.data))
        }
      })
    },
    initInvoiceType(){
      this.$request.post('/dic/queryDicByType?type=INVOICE_TYPE').then(res => {
        if(res.code === "200") {
          localStorage.setItem("invoiceTypeList",JSON.stringify(res.data))
        }
      })
    },
    initForType(){
      this.$request.post('/dic/queryDicByType?type=GENERATE_TYPE').then(res => {
        if(res.code === "200") {
          localStorage.setItem("forTypeList",JSON.stringify(res.data))
        }
      })
    }

  },
  computed: {
    handelConStatus() {
      return function(statusCode) {
        let iconColor;
        switch (statusCode) {
          case "approval":
          case "negotiating":
            iconColor = "bg-blue";
            break;
          case "approved":
          case "stamped":
          case "archived":
            iconColor = "bg-dark-green";
            break;
          case "rejected":
            iconColor = "bg-pink";
            break;
          case "withdrawn":
            iconColor = "bg-dark-gray";
            break;
        }
        return iconColor;
      };
    },
    handelPayStatus() {
      return function(statusCode) {
        let iconColor;
        switch (statusCode) {
          case "unpaid":
            iconColor = "bg-gray";
            break;
          case "paying":
          case "partially":
            iconColor = "bg-blue";
            break;
          case "completed":
            iconColor = "bg-dark-green";
            break;
        }
        return iconColor;
      };
    }
  },
  created() {
    // 获取草稿数量
    this.getDraftCount();
    // 获取筛选列表里的主体列表
    this.getSearchList();
    // 获取筛选列表里的经办人列表
    this.getSearchOperaterList();
    // 获取合同台账列表
    this.getContractList();

  },
  mounted() {
    this.initCurrency()
    this.initInvoiceType()
    this.initForType()
  },
  updated(){
    this.$nextTick(() =>{
      setTimeout(() => {
        let fixedBox = this.$refs['fixBox']
        let height = fixedBox.offsetHeight
        let tableBox = this.$refs['tableBox']
        tableBox.style.paddingTop = height + 'px'
      },0)
    })
  }
}
</script>

<style lang="less" scoped>
.content_box {
  min-height: 100vh;
  padding-bottom: 20px;
  .fixed_position {
    box-sizing: border-box;
    width: 100%;
    // height: auto;
    position: fixed;
    z-index: 999;
    top: 0;
    // right: 2px;

    .list-title {
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      padding: 6px 12px;
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      border-bottom: 1px solid #eaeaea;

      .btn-box {
        width: 210px;
        position: relative;

        .draftDisabledClass {
          color: #c1c1c1;
          border: 1px solid #c1c1c1;
          &:hover {
            cursor: no-drop;
          }
        }

        .scaling {
          font-size: 12px;
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: #df6673;
          line-height: 20px;
          text-align: center;
          color: white;
          position: absolute;
          left: 80px;
          top: -4px;
        }
        .scalings {
          border-radius: 10px;
          width: 36px;
          height: 18px;
          left: 66px;
          top: -4px;
        }

        .icon_style {
          font-size: 14px;
          margin-right: 0;
        }
      }
    }

    .searchContent {
      .searchBar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 18px 12px;

        .input-style {
          width: 568px;
          height: 30px;
        }

        .input-style /deep/ .el-input__inner {
          &::placeholder {
            font-weight: 400;
            color: #c1c1c1;
          }
        }

        .search-option {
          color: #997236;
        }
        .caretIcon {
          color: #997236;
          font-size: 14px;
        }

        /deep/.el-input__inner {
          &::placeholder {
            font-size: 12px;
            font-weight: normal !important;
          }
        }
      }

      .searchList {
        box-sizing: border-box;
        width: 100%;
        margin-top: 15px;
        padding: 0 12px;

        .label_style /deep/ .el-form-item__label {
          line-height: 20px;
        }
      }
    }
  }

  .tableContent {
    padding: 6px 12px;
    width: 100%;
    box-sizing: border-box;

    .tableClass {
      ::v-deep .el-table__fixed {
        height: 100% !important;
      }
    }

    .contractNameBox {
      width: 100%;
      display: flex;
      line-height: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }

      .nameLink {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #997236;
        padding: 3px 0px;
      }
      .SupAgreementBox {
        display: inline-block;
        width: 65px;
        height: 18px;
        padding: 2px 6px;
        background-color: #f7ebd4;
        color: #997236;
        line-height: 18px;
        border-radius: 2px;
        position: relative;
        top: 8px;
      }
    }
    .contractNameBox-rescind {
      line-height: 20px;

      .SupAgreementBox-rescind {
        top: -2px;
      }
    }
    .rescindBox {
      width: 100%;
      height: 14px;
      position: relative;
    }
    .rescind {
      width: 42px;
      height: 18px;
      border-radius: 0px 0px 6px 0px;
      background-color: #fedfe7;
      color: #fa5f87;
      text-align: center;
      line-height: 18px;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    .statusBox {
      width: 100%;

      .statusIcon {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
      }
      .statusName {
        display: inline-block;
        padding: 0 6px;
      }
    }

    .emptyBox {
      height: 300px;
      // display: flex;
      div {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -60px;
        margin-left: -100px;

        img {
          width: 100px;
          height: 100px;
        }
        p {
          height: 20px;
        }
      }
    }
    .over-title {
      line-height: 24px;
    }
    .last-title {
      line-height: 2px;
      color: #c1c1c1;
    }
    .pagination {
      height: 36px;
      position: sticky;
      bottom: 0;
      right: 0;
      background: white;
      padding-top: 18px;
      z-index: 500;
      .el-pagination {
        position: absolute;
        right: 20px;
      }
    }
  }
  #agreementListBox {
    width: 168px;
    background-color: #212121;
    opacity: 0.9;
    position: absolute;
    z-index: 1001;
    padding: 5px 0px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #191919;
      // -webkit-border-radius: 2em;
      // -moz-border-radius: 2em;
      // border-radius: 2em;
    }
    li {
      color: white;
      padding: 0 6px;
      cursor: pointer;
    }
  }
}
@media screen and (min-width: 1281px) {
  ::v-deep .el-form-item__label{
    width: 90px !important;
    vertical-align: middle;
    line-height: 32px !important;
  }
  ::v-deep .el-form-item__content{
    margin-left: 90px !important;
  }
}
</style>
<style>
.el-tooltip__popper{
  max-width: 150px !important;
}
.el-select .el-input .el-icon-circle-close::before{
  content: "";
  background: url(../assets/img/Subtract.png) center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
