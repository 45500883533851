import Vue from 'vue' 
export default Vue.directive('supplier', {
    bing:function(el) {
        el.style.background=""
        el.style.color=""
    },
    inserted(el,binding) { 
        let txt = el.innerText
        let color
        let backgroundColor
        if(txt == '双湖寻源') {

                    color='#997236'
                    backgroundColor='#FBF5E9'
        }
        else {

                    color='#5587F0'
                    backgroundColor='#EEF3FD'
        }
        // switch (txt) {
        //     case '双湖寻源':
        //         color='#997236'
        //         backgroundColor='#FBF5E9'
        //         break;
        //         default:
        //         color='#5587F0'
        //         backgroundColor='#EEF3FD'
        //         break;
        // }
        console.log(color)
        el.style.color=color 
        el.style.backgroundColor=backgroundColor
    }
})