import Vue from 'vue'
import VueRouter from 'vue-router'
import ConParameter from '../views/ConParameter.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'ConParameter',
        component: ConParameter
    },
    {
        path: '/bpm',
        name: 'bpm',
        component: () =>
            import ('../views/bpm.vue'),
    },
    {
        path: '/draft',
        name: 'draft',
        component: () =>
            import ('../views/draft.vue')
    },
    {
        path: '/creatcon/',
        name: 'creatCon',
        component: () =>
            import ('../views/creatContract.vue')
    },
    {
        path: '/bpmHistory',
        name: 'bpmHistory',
        component: () =>
            import ('../views/bpmHistory.vue'),
    },
    {
        path: '/creatagree/:id',
        name: 'creatAgree',
        component: () =>
            import ('../views/creatAgreement.vue')
    },
    // {
    //   path: '/creatchange/:id',
    //   name: 'creatChange',
    //   component: () =>
    //     import('../views/creatChange.vue')
    // },
    {
        path: '/agreedetail/:id',
        name: 'agreeDetail',
        component: () =>
            import ('../views/agreeDetail.vue')
    },
    // {
    //   path: '/changedetail/:id',
    //   name: 'changeDetail',
    //   component: () =>
    //     import('../views/changeDetail.vue')
    // },
    {
        path: '/condetail/:id',
        name: 'conDetail',
        component: () =>
            import ('../views/conDetail.vue')
    },
    {
        path: '/coninfo/:id',
        name: 'conInfo',
        component: () =>
            import ('../views/businessInfo_Con.vue')
    },
    {
        path: '/agreeinfo/:id',
        name: 'agreeInfo',
        component: () =>
            import ('../views/businessInfo_Agree.vue')
    },
    {
        path: '/changeinfo/:id',
        name: 'changeInfo',
        component: () =>
            import ('../views/businessInfo_Change.vue')
    },
    {
        path: '/conedit/:id',
        name: 'conEdit',
        component: () =>
            import ('../views/conEdit.vue')
    },
    {
        path: '/agreementedit/:id',
        name: 'agreementEdit',
        component: () =>
            import ('../views/agreeEdit.vue')
    },
    {
        path: '/changededit/:id',
        name: 'changedEdit',
        component: () =>
            import ('../views/changeEdit.vue')
    },
    // 变更类型为框架类协议金额修改-修改页面
    {
      path: '/amountmodifyedit/:id',
      name: 'amountModifyEdit',
      component: () => import ('@/views/amount-modify/Edit.vue')
    },
    {
        path: '/condraft/:id',
        name: 'conDraft',
        component: () =>
            import ('../views/conEdit.vue')
    },
    {
        path: '/agreementdraft/:id',
        name: 'agreementDraft',
        component: () =>
            import ('../views/agreeEdit.vue')
    },
    {
        path: '/dataMove',
        name: 'dataMove',
        component: () =>
            import ('../views/dataMove.vue'),
        redirect: '/dataMove/baseData',
        children: [{
            path: 'agreeMentMove',
            name: 'agreeMentMove',
            component: () =>
                import ('../views/move/agreeMentMove.vue')
        }, {
            path: 'approveRecord',
            name: 'approveRecord',
            component: () =>
                import ('../views/move/approveRecord.vue')
        }, {
            path: 'attachFile',
            name: 'attachFile',
            component: () =>
                import ('../views/move/attachFile.vue')
        }, {
            path: 'baseData',
            name: 'baseData',
            component: () =>
                import ('../views/move/baseData.vue')
        }, {
            path: 'contractPay',
            name: 'contractPay',
            component: () =>
                import ('../views/move/contractPay.vue')
        }, {
            path: 'mainContract',
            name: 'mainContract',
            component: () =>
                import ('../views/move/mainContract.vue')
        }, {
            path: 'modifyMove',
            name: 'modifyMove',
            component: () =>
                import ('../views/move/modifyMove.vue')
        }, {
            path: 'unionOrder',
            name: 'unionOrder',
            component: () =>
                import ('../views/move/unionOrder.vue')
        }, ],
    },
    {
        path: '/contractAssignDetail',
        name: 'contractAssignDetail',
        component: () => import('../views/contractAssignDetail.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
