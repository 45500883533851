import Utils from "./index";
// 格式化空字符 显示"-"
export function empty(value) {
  if (value == "" || value == null) {
    return "-";
  } else {
    return value;
  }
}

// 格式化审批状态名称
export function conStatusNameFilter(value) {
  switch (value) {
    case "draft":
      value = "草稿";
      break;
    case "approval":
      value = "审批中";
      break;
    case "approved":
      value = "已审批";
      break;
    case "rejected":
      value = "被驳回";
      break;
    case "withdrawn":
      value = "已撤回";
      break;
    case "negotiating":
      value = "协商中";
      break;
    case "stamped":
      value = "已盖章";
      break;
    case "archived":
      value = "已归档";
      break;
    default:
      value = '-'

  }
  return value;
}

// 格式化付款状态名称
export function payStatusNameFilter(value) {
  switch (value) {
    case "unpaid":
      value = "未付款";
      break;
    case "paying":
      value = "付款中";
      break;
    case "partially":
      value = "部分已付";
      break;
    case "completed":
      value = "付款完成";
      break;
    case "nopayment":
      value = "无需付款";
      break;
    default:
      value = "-"
  }
  return value;
}

// 格式化比例数据
export function formatRate(value) {
  if (value === '' || value === null) {
    return '-'
  }
  return (Number(value) * 100).toFixed(2) + "%"
}

// 重写Number 的toFixed 方法
Number.prototype.toFixed = function (d) {
  let s = this + "";
  if (!d) {
    d = 0
  }
  if (s.indexOf(".") == -1) {
    s += "."
  }
  s += new Array(d + 1).join("0");
  if (new RegExp("^(-|\\+)?(\\d+(\\.\\d{0," + (d + 1) + "})?)\\d*$").test(s)) {
    s = "0" + RegExp.$2
    let pm = RegExp.$1
    let a = RegExp.$3.length
    let b = true
    if (a == d + 2) {
      a = s.match(/\d/g);
      if (parseInt(a[a.length - 1]) > 4) {
        for (var i = a.length - 2; i >= 0; i--) {
          a[i] = parseInt(a[i]) + 1;
          if (a[i] == 10) {
            a[i] = 0;
            b = i != 1;
          } else break;
        }
      }
      s = a.join("").replace(new RegExp("(\\d+)(\\d{" + d + "})\\d$"), "$1.$2");

    }
    if (b) {
      s = s.substr(1)
    }
    return (pm + s).replace(/\.$/, "");
  }
  return this + ""
}

export function ArrayToString(arr) {
  if (!Object.prototype.toString.call(arr).includes('Array') || !arr.length) {
    return ''
  } else {
    arr = arr.map((item, index) => {
      return index + 1 + "." + item.name + ' '
    })
    return arr.join('')
  }
}

// 多个数据的字符串加序号(逗号)
export function addSerial(value) {
  if (value == '' || value == null) {
    return "-"
  }
  length = value.length
  value = value.slice(1, length - 1)
  let arr = value.split(",")
  if (arr.length == 1) {
    return value;
  }
  arr = arr.map((item, index) => {
    return index + 1 + "." + item;
  });
  return arr.join('，')
}

// 多个数据的字符串加序号(|)
export function addSerialTwo(value) {
  if (value == '' || value == null) {
    return "-"
  }
  length = value.length
  value = value.slice(1, length - 1)
  let arr = value.split("|")
  if (arr.length == 1) {
    return value;
  }
  arr = arr.map((item, index) => {
    return index + 1 + "." + item;
  });
  return arr.join('，')
}

// 千分位分隔符
export function millimeterFormat(value) {
  if (!value) {
    return '-'
  }
  if (value === '0' || value === '0.00' || value === '0.0') {
    return '0.00'
  }
  if (String(value).indexOf('E') !== -1 && String(value).indexOf('e') !== -1) {
    value = Number(value).toFixed(2) + ''
  }
  value = Utils.BigNumberCacl(value, '0', '+')
  // 小数点位置
  let index = value.indexOf('.')
  let intPart = null
  let decimal = null

  if (index !== -1) {
    // 截取整数位
    intPart = value.substring(0, index)

    // 截取小数位
    decimal = value.substring(index + 1, value.length)
  } else {
    intPart = value
    decimal = '00'
  }
  intPart = intPart.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  return intPart + '.' + decimal  // 返回值拼接整数位和小数位
}
