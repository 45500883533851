<template>
  <div id="app">
    <router-view :key="path"/>
  </div>
</template>
<script>
import utils from './utils/index'
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'
import qs from 'qs'

export default {
  computed: {
    path () {
      var pathName = this.$route.path
      if (pathName.startsWith('/dataMove')) {
        return 1
      } else {
        return pathName
      }
    }
  },
  methods: {
    ...mapActions(['getUser']),
    getCASTGC(){
      this.$request.get('/employee/getCASTGC').then(res => {
        if(res && res.code === "200") {
          let castgc = utils.decrypt(res.data, 'NFwED1(j3C*let0A', '$UzMl&T&9O9rv*cV');
          sessionStorage.setItem('castgc', castgc)
          this.$store.commit('setCASTGC', castgc)
          // this.getAuthority()
        }
      })
    },
    // 获取用户权限
    getAuthority() {
      this.$request.post('/authority/getAuthority').then(res =>{
        if(res && res.code === '200' && res.data) {
          let authority = res.data
          this.SET_AUTHORITY(authority)
          // const castgc = sessionStorage.getItem('castgc')
          // const storageName = `authority|${window.authority}`
          // sessionStorage.setItem(storageName, JSON.stringify(authority))
        }
      })
    }
  },
  mounted() {
    const { token } = qs.parse(window.location.hash.replace(/(.*)(?=\?)/, ''), { ignoreQueryPrefix: true })
    
    /**
     * 本地开发环境
     * - 判断URL上是否存在 token值
     * - 如果存在，则存储到本地Cookie
     * - 请求接口会携带 withCredentials
     */
    if (process.env.NODE_ENV === 'development' && token) {
      Cookies.set('CASTGC', token)
    }
    this.getCASTGC()
    this.getUser()
    // let a = 12345678901234567890
    // console.log(utils.BigNumberCacl('123456789123456789', '0.00', '*'))
    // console.log(utils.BigNumberCacl('100.00', '400000000000000000.00', '-'))
  }
}
</script>

<style lang="less">
@import "./styles/common/common.less";
::-webkit-scrollbar {
  width: 6px; /* 纵向滚动条*/
  height: 6px; /* 横向滚动条 */
  background-color: #FFF;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  // background-color: #D6D6D6;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #D6D6D6;
  border-radius: 3px;
}
::-webkit-scrollbar-button {
  display: none;
}
</style>
