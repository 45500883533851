import Vue from 'vue'
import Vuex from 'vuex'
import request from '../api/index'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        CASTGC: "",
        authority: []
    },
    mutations: {
        setCASTGC(state, data) {
            state.CASTGC = data
        },
        SET_AUTHORITY(state, data) {
          state.authority = data || []
        }
    },
    actions: {
      getUser({ commit }) {
        request.post('/authority/getAuthority').then(res =>{
          if(res && res.code === '200' && res.data) {
            let authority = res.data
            commit('SET_AUTHORITY', authority)
          }
        })
      }
    },
    modules: {}
})
