const baseData = {
        queryCompany: '/dmenterprise/queryCompany' // 基础数据查询
    }
    // 主合同数据
const mainContract = {
        queryCostContractList: '/dmcontract/queryCostContractList', //获取列表
        deleteCostContract: '/dmcontract/deleteCostContract', // 删除列表
        unSyncCostContract: '/dmcontract/unSyncCostContract', // 取消同步
        syncMainContractData: '/dmcontract/syncAllData', //同步数据
        refreshVersion: '/dmmodify/refreshVersion'
    }
    // 工联单数据
const unionOrder = {
        queryBillsContractList: '/dmcontract/queryBillsContractList', // 获取列表
        deleteBillsContract: '/dmcontract/deleteBillsContract', // 删除列表
        relevantBillsContract: '/dmcontract/relevantBillsContract', // 查询合同
        relevantContract: '/dmcontract/relevantContract', // 选择关联合同
        unRrelevantContract: '/dmcontract/unRrelevantContract', // 取消关联
        unSyncBills: 'dmcontract/unSyncBills' // 取消同步
    }
    // 合同变更数据
const agreeMentMove = {
        agreeMentMoveList: '/dmagreement/queryMoveAgreeMentList', // 补充协议数据台账
        deleteDMModify: '/dmmodify/deleteDMModify', // 补充协议数据删除
        importAgreeMentMove: '/cms/inner/dmagreement/importAgreement', //导入数据
        deleteDMAgreeMent: '/dmagreement/deleteDMAgreeMent', // 删除列表数据
        unSyncModify: '/dmmodify/unSyncModify', // 取消同步
        unSyncAgreeMent: '/dmagreement/unSyncAgreeMent'
    }
    // 合同变更数据
const modifyMove = {
        modifyMoveList: '/dmmodify/queryMoveModifyList', // 合同变更数据台账
        deleteModifyMove: '', // 合同变更数据删除
        importModifyMove: '/cms/inner/dmmodify/importModify' //导入数据
    }
    // 合同附件数据
const attachFile = {
        attachFileList: '/dmfile/getDMFileList', // 合同附件数据台账
        deleteAttachFile: '/dmfile/deleteDMFile', // 合同附件数据删除
        unSyncAttachFile: '/dmfile/unSycnDMFile', // 取消同步
    }
    // 合同付款数据
const contractPay = {
        contractPayList: '/dmApplyPayment/getDMApplyList', // 付款记录台账
        deletecontractPay: '/dmApplyPayment/deleteDMApply', // 付款记录删除
        unSyncContractPay: '/dmApplyPayment/cancelSyncDMApply', // 取消同步
    }
    // 审批记录数据
const approveRecord = {
        bpmHistory: 'dmApprovalRecord/getDMApprovalInfo', // 详情页面
        approveRecordList: '/dmApprovalRecord/getDMApprovalList', // 审批记录台账
        deleteApproveRecordMessage: 'dmApprovalRecord/deleteDMApproval', // 审批记录删除
        unSyncApproveRecord: '/dmApprovalRecord/cancelSyncDMApproval', // 取消同步
    }
const contractAssign = {
    getAssignmentList: '/assignment/getAssignmentList',
    startAssignment: '/assignment/startAssignment',
    getAssignmentInfo: '/assignment/getAssignmentInfo',
    todoAction: '/assignment/todoAction' // 指派代办接受，拒绝
}
    // 共用接口
const commonInterface = {
    syncAllData: '/dmcontract/syncAllData'
}
// 文档库接口
const openApi = {
    fileUrl: '/cms/inner/file/fileUrl'
}

export default {
    ...baseData,
    ...mainContract,
    ...modifyMove,
    ...attachFile,
    ...contractPay,
    ...approveRecord,
    ...unionOrder,
    ...agreeMentMove,
    ...commonInterface,
    ...openApi,
    ...contractAssign
}